<template>
  <div class="home">
    <Title>首页</Title>
    <!--    banner-->
    <div class="banner">
      <banner-component/>
    </div>
<!--    tabs-->
    <opus-group/>
    <div class="bottomBox" ref="bottomBoxRef"></div>
  </div>
</template>
<script setup lang="ts">
import bannerComponent from './components/banner.vue'
import opusGroup from './components/opusGroup.vue'
import { ChannelApi, AppDesignListApi } from "@/apis/api";
let channelApi = new ChannelApi();
let appDesignListApi = new AppDesignListApi();
const router = useRouter();
let bottomBoxRef=ref()
provide("bottomBoxRef",bottomBoxRef)
const data: any = reactive({
  communityContentList: [], //精选内容
  goodsList: [{}, {}, {}, {}], //推荐好物数组
  designListType: null, //1:设计师和设计机构，2：装修公司
  pageIndex: null,
  pageCount: null,
  seeGetList: [], //所见所得
  bannerList: [],
});
enum PageType {
  "/videoDetail" = 5, //视频
  "/imgTextDetail" = 6, //图文
  "/caseDetail" = 9, //整屋案例
}

// 获取社区精选内容
const { data: communityContentList } = await useAsyncData("getCommunityContentList", async () => {
  let res = await channelApi.apiAppChannelGetDataPost(
    {
      firstType: 3,
      pageIndex: 1,
      pageCount: 10,
      appType: 3,
    },
    { headers: useRequestHeaders(["cookie"]) }
  );
  return res.data.items;
});

//获取最受欢迎装修公司列表
const { data: decorationCompanyList } = await useAsyncData("getCompanyList", async () => {
  data.pageIndex = 1;
  data.pageCount = 6;
  data.designListType = 2;
  let { pageIndex, pageCount, designListType } = data;
  let res = await appDesignListApi.apiAppAppDesignListGetDesignListPost({
    pageIndex,
    pageCount,
    designListType,
  });
  return res.data.items;
});
//获取最受欢迎设计师列表
const { data: decorationDesignerList } = await useAsyncData("getDesignerList", async () => {
  data.pageIndex = 1;
  data.pageCount = 8;
  data.designListType = 1;
  let { pageIndex, pageCount, designListType } = data;
  let res = await appDesignListApi.apiAppAppDesignListGetDesignListPost({
    pageIndex,
    pageCount,
    designListType,
  });
  return res.data.items;
});
// 获取所见所得列表
const { data: seeGetList } = await useAsyncData("getSeeGetList", async () => {
  let params = {
    cityCode: "",
    areaCode: "0",
    styleCode: "0",
    room: 0,
    pageIndex: 1,
    pageCount: 4,
  };
  let res = await channelApi.apiAppChannelGetYouSeePost(params, { headers: useRequestHeaders(["cookie"]) });
  return res.data.items;
});
// 查看作品内容详情
const toImgTextDetails = (opus) => {
  const routerUrl = router.resolve({
    path: PageType[opus.platformCaseType],
    query: {
      id: opus.id,
    },
  });
  window.open(routerUrl.href, "_blank");
};
onMounted(()=>{

})
</script>
<style lang="less" scoped>
.home {
  font-family: PingFangSC, PingFangSC-Medium;
  box-sizing: border-box;
  min-width: 1130px;
  width: 1512px;
  margin: 0 auto;
  padding-top: 20px;
  //banner
  .banner {
    width: 100%;
    margin-bottom: 60px;
  }
  .bottomBox{
    height: 50px;
  }
  //回到顶部
  .back-top {
    @apply fixed;
    right: 5px;
    bottom: 284px;
  }
}
@media (max-width: 1450px) {
  .home{
    width: 1130px;
  }
}
</style>
